<template>
    <section id="login" class="py-5">
        <the-banner-small>Site Management</the-banner-small>
        <div class="container my-3">
            <div class="row justify-content-center p-3" v-if="error">
                <div class="col-12 p-3 border text-center">
                    <p>There was an error logging in. Please check your credentials and try again.</p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-6 p-3 border">
                    <form @submit.prevent="login">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input type="email" class="form-control" placeholder="Gebruikersnaam" v-model="email">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input type="password" class="form-control" placeholder="Paswoord" v-model="password">
                        </div>
                        <input type="submit" value="Submit" class="btn btn-secondary btn-block">
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../../components/layout/TheBannerSmall'
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ENV_BASE_URL

export default {
    data () {
        return {
            error: false,
            email: '',
            password: ''
        }
    },
    components: {
        TheBannerSmall
    },
    methods: {
        login () {
            if (localStorage.getItem('authenticated') === 'true') {
                this.$router.replace({ name: 'Beheer' })
            } else {
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post('/login', {
                        email: this.email,
                        password: this.password
                    }).then(response => {
                        this.$store.dispatch('auth/login')
                        this.$router.replace({ name: 'Beheer' })
                    }).catch(error => {
                        this.error = true
                        console.log(error.response.data.message)
                    })
                })
            }
        }
    }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>
